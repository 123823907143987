@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;600;700;900&display=swap");

// Only for the logo, therefor proviging `text` parameter
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed:wght@600&display=swap&text=passport-selfie.com");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Encode Sans", sans-serif;
    line-height: 1.75;
  }
}

@layer utilities {
  .clip-hidden {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  .clip-visible {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  .notif-hidden {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  }
  // via https://gist.github.com/dfrankland/f6fed3e3ccc42e3de482b324126f9542
  .bg-checkers {
    background-image: linear-gradient(45deg, #eee 25%, transparent 25%),
      linear-gradient(135deg, #eee 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #eee 75%),
      linear-gradient(135deg, transparent 75%, #eee 75%);
    background-size: 26px 26px;
    background-position: 0 0, 13px 0, 13px -13px, 0px 13px;
  }

  .border-dashed-nice {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%237964E4' stroke-width='2' stroke-dasharray='3%25' stroke-dashoffset='17' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 12px;
  }
}
